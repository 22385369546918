import { Button, Input, notification, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import Icon from '../components/Icon'

import { useStore } from '../store'
import { useTimer } from '../hooks/useTimer'
import { useAudio } from '../hooks/useAudio'
import { useWebRTC } from '../hooks/useWebRTC'
import { request, validateContent } from '../utils/api'

import '../assets/styles/chat.less'
import logo from '../assets/elai-logo.svg'
import avatarPreview from '../assets/avatar.png'

const defaultVoice = 'elevenlabs@EXAVITQu4vr4xnSDxMaL'
const canUseApp = !!window.webkitSpeechRecognition

const SESSION_TIME = 2 * 60 * 1000

const Stream = () => {
  const [avatars, setAvatars] = useState([])
  const [voiceId, setVoiceId] = useState(defaultVoice)
  const [newMessage, setNewMessage] = useState()
  const [isSessionActive, setIsSessionActive] = useState(false)
  const [isCreatingSession, setIsCreatingSession] = useState(false)
  const [creatingSessionDescription, setCreatingSessionDescription] = useState('')
  const [selectedAvatar, setSelectedAvatar] = useState(null)
  const [isSendingRenderRequest, setIsSendingRenderRequest] = useState(false)
  const [sessionEndTime, setSessionEndTime] = useState(null)

  const { user, refreshSession } = useStore((stores) => stores.authStore)
  const { messages, setMessages, getStreamId, refreshStreamState } = useStore((stores) => stores.appStore)

  const videoRef = useRef(null)

  const { record, stop, isRecordingAudio, isRecognitionEnabled } = useAudio({
    setNewMessage,
    setIsSendingRenderRequest,
  })

  const { startStream, endStream, getStream, clearStreamSessionData } = useWebRTC({
    videoRef,
    setIsSessionActive,
    setIsCreatingSession,
    setCreatingSessionDescription,
    selectedAvatar,
    setIsSendingRenderRequest,
    voiceId,
  })

  const { sessionEndMins, sessionEndSecs, diff } = useTimer({ sessionEndTime, clearStreamSessionData })

  const handleAvatarChange = (value) => {
    setSelectedAvatar(avatars.find((a) => a.code === value))
  }

  const sendChatRequest = async (message) => {
    if (videoRef.current?.muted) videoRef.current.muted = false
    if (!validateContent(message)) return
    if (!getStreamId()) {
      clearStreamSessionData()
      return
    }
    setIsSendingRenderRequest(true)
    const data = { messages: [...messages, { role: 'user', content: message }] }
    if (!messages.length) data.firstMessage = message
    setMessages(data.messages)
    const resp = await request({
      method: 'post',
      url: `/stream/chat/${getStreamId()}`,
      data,
    })
    if (!resp) {
      clearStreamSessionData()
      setIsSendingRenderRequest(false)
      return
    }
    setTimeout(() => {
      setIsSendingRenderRequest(false)
      setSessionEndTime(new Date().getTime() + SESSION_TIME)
      refreshSession()
    }, 3500)
    setMessages(resp.messages)
  }

  const startChatSession = async () => {
    setIsCreatingSession(true)
    if (!user?.id) await fetchAvatars()
    startStream()
  }

  const fetchAvatars = async () => {
    const data = await request({ method: 'get', url: '/avatars', params: { realtime: 1 } })
    if (data?.length) {
      console.log({ data })
      setAvatars(data)
      setSelectedAvatar(data[0])
    }
  }

  useEffect(() => {
    if (selectedAvatar?.code) {
      setVoiceId(selectedAvatar.defaultVoice || defaultVoice)
    }
  }, [selectedAvatar])

  useEffect(() => {
    refreshStreamState()
    if (!canUseApp) {
      notification.error({
        message:
          'This demo is working only in Chrome v33, Microsoft Edge v79, Safari v14.1 and above versions. Please use other browser to check this demo.',
      })
      return
    }
    if (getStreamId() && user?.id) {
      setIsCreatingSession(true)
      setCreatingSessionDescription('Restoring your stream...')
      getStream().then(async (stream) => {
        if (!stream?.id) {
          clearStreamSessionData()
          await fetchAvatars()
          return
        }
        if (!selectedAvatar) {
          fetchAvatars().then(() => {
            // timeout is for a correct update setSelectedAvatar in fetchAvatars
            setTimeout(() => startStream(stream), 400)
          })
        } else {
          startStream(stream)
        }
      })
      return
    }
    if (user?.id) fetchAvatars()
    const av = new Image()
    av.src = avatarPreview
  }, [])

  useEffect(() => {
    if (!newMessage) return
    sendChatRequest(newMessage)
    setNewMessage(null)
  }, [newMessage])

  useEffect(() => {
    if (isSessionActive) setSessionEndTime(new Date().getTime() + SESSION_TIME)
    else setSessionEndTime(null)
  }, [isSessionActive])

  return (
    <>
      <div
        className={`chat ${isSessionActive && !isCreatingSession ? 'chat-session-active' : 'chat-session-inactive'}`}
      >
        <div
          className={`chat-left ${
            isSessionActive && !isCreatingSession ? 'chat-left-session-active' : 'chat-left-session-inactive'
          }`}
        >
          <div className="intro">
            <img src={logo} alt="elai-logo" className="logo" />
            <div className="title">
              Welcome to <span style={{ color: '#4868FF' }}>Real-time chat</span>!
            </div>
            <ul>
              <li>Interact instantly. Use real-time updates</li>
              <li>Speak and receive immediate responses</li>
              <li>Engage in live audio communication</li>
              <li>Track live data streams. Make instant decisions</li>
            </ul>
          </div>
          <div className="start-chat-button-wrapper">
            <Button
              type="primary"
              onClick={startChatSession}
              loading={isCreatingSession}
              className="start-chat-button"
              disabled={!canUseApp}
            >
              Start chat <Icon name="right_arrow" />
            </Button>
            <div className="description">{creatingSessionDescription}</div>
          </div>
          {user?.isAdmin && !isCreatingSession && (
            <>
              <h4>Admin session parameters:</h4>
              <div className="admin">
                <div>Avatar: </div>
                <div>
                  <Select
                    showSearch
                    placeholder="Select an avatar"
                    // optionFilterProp="children"
                    virtual={false}
                    onChange={handleAvatarChange}
                    style={{ width: '100%' }}
                    value={selectedAvatar?.code}
                    options={avatars?.map((a) => ({ value: a.code, label: a.name }))}
                  />
                </div>
                <div>Voice ID (Eleven labs only):</div>
                <div>
                  <Input
                    placeholder="voice ID (only 11labs)"
                    onChange={(e) => setVoiceId(e.target.value)}
                    defaultValue={voiceId}
                    value={voiceId}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={`chat-right ${
            isSessionActive && !isCreatingSession ? 'chat-right-session-active' : 'chat-right-session-inactive'
          }`}
        >
          <div className="video-wrapper">
            {!isSessionActive && isCreatingSession && (
              <Spin style={{ position: 'absolute', top: '50%', left: '50%' }} />
            )}
            <video ref={videoRef} autoPlay muted={true} />
            {isSessionActive && (
              <>
                <Tooltip
                  title={
                    !isSendingRenderRequest &&
                    (isRecordingAudio ? 'Click to stop recording' : 'Click to start recording')
                  }
                >
                  <Button
                    type="primary"
                    shape="circle"
                    disabled={isCreatingSession || !isRecognitionEnabled || !isSessionActive || isSendingRenderRequest}
                    icon={<Icon name={`${isRecordingAudio ? 'stop' : 'mic'}`} />}
                    onClick={isRecordingAudio ? stop : record}
                    className="record-audio-button"
                  />
                </Tooltip>
                <div
                  className={`record-audio-button-border ${
                    isSendingRenderRequest ? 'record-audio-button-border-sending' : ''
                  }`}
                ></div>
              </>
            )}
          </div>
          {isSessionActive && (
            <Button type="primary" onClick={endStream} style={diff < 30 ? { width: 220 } : {}}>
              {diff < 30 ? `End stream (expires in ${sessionEndMins}:${sessionEndSecs})` : 'End stream'}
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default Stream
